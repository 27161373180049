import React from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';

function OnlineModal(props) {
  const {data, onClose} = props;

  return (
    <Modal
      visible={!!data}
      width={750}
      onCancel={onClose}
      footer={null}
      maskClosable={true}
      destroyOnClose={true}>
      <ModalContent {...props} />
    </Modal>
  );
}

function ModalContent(props) {
  const {data, onClose} = props;

  if (data.collection === 'Book') {
    return (
      <ModalContentWrapper>
        <div className="img-container">
          <img alt="cover" src={data.cover} style={{width: 150}} />
          <div style={{marginTop: 20, textAlign: 'center'}}>
            <a target="_blank" href={data.url} rel="noreferrer">
              書目連結
            </a>
          </div>
        </div>
        <div className="container">
          <h2>{data.title}</h2>
          <h3>{data.author}</h3>
          <h3>{data.type_name}</h3>
          <div dangerouslySetInnerHTML={{__html: data.description}} />
        </div>
      </ModalContentWrapper>
    );
  } else if (data.collection === 'Publication_Front') {
    return (
      <ModalContentWrapper>
        <div className="img-container">
          <img alt="cover" src={data.cover} style={{width: 150}} />
        </div>
        <div className="container">
          <h2>{data.title}</h2>
          <h3>{data.author}</h3>
          <h3>{data.publications_name}</h3>
          <div dangerouslySetInnerHTML={{__html: data.description}} />
        </div>
      </ModalContentWrapper>
    );
  } else if (data.collection === 'LegalJournal') {
    return (
      <ModalContentWrapper>
        <div className="container">
          <h2>{data.Period}</h2>
          <h3>目錄</h3>
          <hr />
          <div dangerouslySetInnerHTML={{__html: data.Catalog}} />
          <h3>內容</h3>
          <hr />
          <div dangerouslySetInnerHTML={{__html: data.Content}} />
        </div>
      </ModalContentWrapper>
    );
  }

  return null;
}

const ModalContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > .img-container {
    max-width: 180px;
    width: 100%;
    padding: 15px;
  }

  & > .container {
    flex: 1;
    padding: 15px;
    min-width: 300px;
    max-width: 570px;
    width: 100%;
  }
`;

export default OnlineModal;
