import React from 'react';
import styled from 'styled-components';
import {Menu, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {BookOpen} from '@styled-icons/boxicons-regular/BookOpen';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import SearchCollection from '../../Components/SearchCollection';
import OnlineModal from '../../Components/OnlineModal';

const TITLE = '法律服務';
const ROUTES = [
  {key: '/law-service?group=periodicals', label: '律師法學期刊', children: []},
  {
    key: '/law-service?group=online',
    label: '出版線上',
    children: [
      {
        key: '/law-service?group=online&category=book',
        label: '新書通報',
        children: [],
      },
      {
        key: '/law-service?group=online&category=magazine',
        label: '會刊',
        children: [],
      },
      {
        key: '/law-service?group=online&category=legal-journal',
        label: '歷年法學期刊',
        children: [],
      },
      {
        key: '/law-service?group=online&category=publication',
        label: '公會出版品',
        children: [],
      },
      {
        key: '/law-service?group=online&category=periodical',
        label: '律師雜誌',
        children: [],
      },
    ],
  },
  {
    key: '/law-service?group=internal',
    label: '國內法律資料庫網站',
    children: [],
  },
  {
    key: '/law-service?group=external',
    label: '國外法律資料庫網站',
    children: [],
  },
  {key: '/law-service?group=search', label: '法律類搜尋引擎', children: []},
  {
    key: '/law-service?group=library',
    label: '圖書資訊服務',
    children: [
      {
        key: '/law-service?group=library&category=introduction',
        label: '館藏介紹',
        children: [],
      },
      {
        key: '/law-service?group=library&category=fax',
        label: '傳真檢索',
        children: [],
      },
      {
        key: '/law-service?group=library&category=search',
        label: '館藏檢索',
        children: [],
      },
      {
        key: '/law-service?group=library&category=university-serial',
        label: '大學期刊檢索',
        children: [],
      },
      {
        key: '/law-service?group=library&category=borrow',
        label: '各大學圖書館借閱方式',
        children: [],
      },
    ],
  },
  {
    key: 'https://portal.ezlawyer.com.tw/Login.do',
    label: '司法院律師單一入口',
    children: [],
  },
  {key: '/law-service?group=column', label: '法律普及專欄', children: []},
  {key: '/law-service?group=anti-money', label: '洗錢防制法', children: []},
  {
    key: '/law-service?group=lawyer-law',
    label: '律師法',
    children: [],
    query: {
      labels: '法律服務-律師法',
    },
  },
  {
    key: '/law-service?group=lawyer-school',
    label: '臺灣律師學院',
    children: [],
  },
];

function LawService(props) {
  const params = qs.parse(props.location.search);
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const [record, setRecord] = React.useState(null);

  const [modalData, setModalData] = React.useState(null);

  const handleCustomComponent = (search) => {
    if (search.indexOf('?group=library&category=search') > -1) {
      return [
        true,
        <SearchCollection
          collection="Library_Info"
          columns={[
            {title: '書名', dataIndex: 'name', key: 'title', width: '20%'},
            {title: '作者', dataIndex: 'author', key: 'author', width: '15%'},
            {
              title: '出版者',
              dataIndex: 'publisher',
              key: 'publisher',
              width: '15%',
            },
            {
              title: '櫃號',
              dataIndex: 'cabinet_number',
              key: 'cabinet_number',
              width: '10%',
            },
            {title: '卷', dataIndex: 'reel', key: 'reel', width: '2%'},
            {title: '期', dataIndex: 'period', key: 'period', width: '2%'},
            {title: 'ISBN', dataIndex: 'isbn', key: 'isdb', width: '2%'},
            {title: 'ISSN', dataIndex: 'issn', key: 'issn', width: '2%'},
            {title: 'ISRC', dataIndex: 'isrc', key: 'isrc', width: '2%'},
            {title: 'ISMN', dataIndex: 'ismn', key: 'ismn', width: '2%'},
            {title: 'GPN', dataIndex: 'gpn', key: 'gpn', width: '2%'},
          ]}
          query={(keyword) => ({
            $or: [
              {name: {$regex: keyword}},
              {author: {$regex: keyword}},
              {publisher: {$regex: keyword}},
              {isbn: {$regex: keyword}},
            ],
            is_open: '1',
          })}
        />,
      ];
    } else if (
      search.indexOf('?group=library&category=university-serial') > -1
    ) {
      return [
        true,
        <SearchCollection
          collection="Serial"
          columns={[
            {title: '書名', dataIndex: 'title', key: 'title', width: '20%'},
            {title: '期數', dataIndex: 'VOL_NO', key: 'VOL_NO', width: '10%'},
            {
              title: '目錄',
              dataIndex: 'catalog',
              key: 'catalog',
              render: (_, record) => {
                return (
                  <ol>
                    {record.catalog?.map((c) => (
                      <li>
                        <span style={{marginRight: 5}}>{c.WRITER} -</span>
                        <span style={{marginRight: 5}}>{c.TOPIC} - </span>
                        <span>第 {c.PAGE} 頁</span>
                      </li>
                    ))}
                  </ol>
                );
              },
            },
          ]}
          query={(keyword) => ({
            $or: [
              {title: {$regex: keyword}},
              {VOL_NO: {$regex: keyword}},
              {
                catalog: {
                  $elemMatch: {
                    TOPIC: {$regex: keyword},
                  },
                },
              },
              {
                catalog: {
                  $elemMatch: {
                    WRITER: {$regex: keyword},
                  },
                },
              },
            ],
          })}
        />,
      ];
    } else if (search.indexOf('?group=online&category=book') > -1) {
      return [
        true,
        <SearchCollection
          collection="Book"
          shortcutOptions={[
            '三民出版社',
            '五南出版社',
            '元照出版有限公司',
            '商周出版社',
            '新學林出版股份有限公司',
            '永然出版社',
            '翰蘆圖書出版有限公司',
          ]}
          columns={[
            {
              title: '封面',
              key: 'cover',
              dataIndex: 'cover',
              render: (_, record) => (
                <img alt="book-cover" src={record.cover} style={{width: 75}} />
              ),
            },
            {
              title: '標題',
              key: 'title',
              dataIndex: 'title',
            },
            {
              title: '作者',
              key: 'author',
              dataIndex: 'author',
            },
            {
              title: '出版社',
              key: 'type_name',
              dataIndex: 'type_name',
            },
            {
              title: '',
              detaIndex: 'detail',
              key: 'detail',
              render: (_, record) => (
                <Button
                  type="primary"
                  onClick={() => setModalData({...record, collection: 'Book'})}>
                  詳情
                </Button>
              ),
            },
          ]}
          query={(keyword) => ({
            $or: [
              {title: {$regex: keyword}},
              {author: {$regex: keyword}},
              {type_name: {$regex: keyword}},
            ],
          })}
        />,
      ];
    } else if (search.indexOf('?group=online&category=magazine') > -1) {
      return [
        true,
        <>
          <div style={{fontSize: '1rem', lineHeight: '2rem'}}>
            本欄位所刊各期『在野法潮』內容，各篇圖文如作者不欲於本網站揭露，請致電（02）23515071分機33或電子郵件tbax@ms17.hinet.net予本公會圖書資訊室王冠智先生辦理所屬著作之下架。
          </div>
          <SearchCollection
            collection="Magazine"
            columns={[
              {
                title: '封面',
                key: 'cover',
                dataIndex: 'cover',
                render: (_, record) => (
                  <img
                    alt="book-cover"
                    src={record.cover}
                    style={{width: 75}}
                  />
                ),
              },
              {
                title: '標題',
                key: 'title',
                dataIndex: 'title',
              },
              {
                title: '期數',
                key: 'period',
                dataIndex: 'period',
              },
              {
                title: '試閱版',
                key: 'pdf_sample',
                dataIndex: 'pdf_sample',
                render: (_, record) => (
                  <a target="_blank" href={record.pdf_sample} rel="noreferrer">
                    PDF試閱版
                  </a>
                ),
              },
              {
                title: '完整版',
                key: 'pdf',
                dataIndex: 'pdf',
                render: (_, record) => (
                  <a target="_blank" href={record.pdf} rel="noreferrer">
                    PDF完整版
                  </a>
                ),
              },
              {
                title: '',
                key: 'view-detail',
                render: (_, record) => (
                  <Button
                    type="primary"
                    onClick={() => {
                      AppActions.navigate(
                        `/law-service/magazine/?id=${record.id}`,
                      );
                    }}>
                    查看詳情
                  </Button>
                ),
              },
            ]}
            query={(keyword) => ({
              $or: [{title: {$regex: keyword}}, {period: Number(keyword)}],
            })}
          />
        </>,
      ];
    } else if (search.indexOf('?group=online&category=publication') > -1) {
      return [
        true,
        <SearchCollection
          collection="Publication_Front"
          columns={[
            {
              title: '封面',
              key: 'cover',
              dataIndex: 'cover',
              render: (_, record) => (
                <img alt="book-cover" src={record.cover} style={{width: 75}} />
              ),
            },
            {
              title: '標題',
              key: 'title',
              dataIndex: 'title',
            },
            {
              title: '作者',
              key: 'author',
              dataIndex: 'author',
              width: 200,
            },
            {
              title: '出版者',
              key: 'publications_name',
              dataIndex: 'publications_name',
            },
            {
              title: '',
              detaIndex: 'detail',
              key: 'detail',
              render: (_, record) => (
                <Button
                  type="primary"
                  onClick={() =>
                    setModalData({...record, collection: 'Publication_Front'})
                  }>
                  詳情
                </Button>
              ),
            },
          ]}
          query={(keyword) => ({
            $or: [
              {title: {$regex: keyword}},
              {author: {$regex: keyword}},
              {publications_name: {$regex: keyword}},
            ],
          })}
        />,
      ];
    } else if (search.indexOf('group=online&category=periodical') > -1) {
      return [
        true,
        <SearchCollection
          collection="Lawyer_Magazine"
          columns={[
            {
              title: '標題',
              key: 'title',
              dataIndex: 'title',
            },
            {
              title: '',
              key: 'file',
              dataIndex: 'file',
              render: (_, record) => (
                <a target="_blank" href={record.file} rel="noreferrer">
                  檔案下載
                </a>
              ),
            },
          ]}
          query={(keyword) => ({
            $or: [{title: {$regex: keyword}}],
          })}
        />,
      ];
    } else if (search.indexOf('group=online&category=legal-journal') > -1) {
      return [
        true,
        <SearchCollection
          collection="LegalJournal"
          columns={[
            {
              title: '期數',
              key: 'Period',
              dataIndex: 'Period',
            },
            {
              title: '',
              detaIndex: 'detail',
              key: 'detail',
              render: (_, record) => (
                <Button
                  type="primary"
                  onClick={() =>
                    setModalData({...record, collection: 'LegalJournal'})
                  }>
                  詳情
                </Button>
              ),
            },
          ]}
          query={(keyword) => ({
            $or: [{title: {$regex: keyword}}],
          })}
        />,
      ];
    }

    return [false, null];
  };

  const fetchData = React.useCallback(async () => {
    let route = getRouteInstance(params);
    let isCustomComponent = handleCustomComponent(props.location.search)[0];

    if (!route || isCustomComponent) {
      setRecord(null);

      return;
    }

    try {
      AppActions.setLoading(true);

      if (route.children.length === 0) {
        if (!record || record.title !== route.label) {
          const query = route.query || {};

          setRecord(
            await JStorage.fetchOneDocument('Article_Default', {
              title: route.label,
              ...query,
            }),
          );
        }
      }

      AppActions.setLoading(false);
    } catch (err) {
      console.warn(err);
      setRecord(null);

      AppActions.setLoading(false);
    }
  }, [params, record, props.location.search]);

  React.useEffect(() => {
    fetchData();
  }, [params, fetchData, record]);

  const getRouteInstance = ({group, category}) => {
    let element;

    if (group) {
      element = ROUTES.find((item) => item.key.indexOf(group) > -1);

      if (category) {
        element = element.children.find(
          (item) => item.key.indexOf(category) > -1,
        );
      }
    }

    return element;
  };

  return (
    <Wrapper mobile={mobile}>
      <div className="menu">
        <Menu mode="inline">
          <Menu.Item className="header">{TITLE}</Menu.Item>
          {ROUTES.map((item, index) => {
            if (item.children.length > 0) {
              return (
                <Menu.SubMenu
                  key={`root-${index}`}
                  className="route"
                  title={item.label}>
                  {item.children.map((subroute, index) => (
                    <Menu.Item
                      key={`subroute-${index}`}
                      onClick={() => AppActions.navigate(subroute.key)}>
                      {subroute.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={`root-${index}`}
                className="route"
                onClick={() => AppActions.navigate(item.key)}>
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      <div className="gutter" />
      <div className="main">
        <h1 className="title">{getRouteInstance(params)?.label || '-- --'}</h1>

        {handleCustomComponent(props.location.search)[0] && (
          <div className="content">
            {handleCustomComponent(props.location.search)[1]}
          </div>
        )}

        {record && (
          <div className="content">
            <RichTextPreview key={record.id} content={record.content} />
          </div>
        )}
      </div>

      <Button
        shape="circle"
        size="large"
        className="rwd-fixed-button"
        icon={<BookOpen size={22} color="var(--primaryColor)" />}
        onClick={() => setMobileNavVisible(true)}
      />
      <MobileNavDrawer
        visible={mobile && mobileNavVisible}
        setVisible={setMobileNavVisible}
      />

      <OnlineModal data={modalData} onClose={() => setModalData(null)} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  display: flex;
  justify-content: center;

  & > .menu {
    max-width: 210px;
    width: 100%;

    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}

    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  & > .gutter {
    width: 20px;
    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}
  }

  & > .main {
    max-width: 910px;
    width: 100%;
    padding: 10px;

    & > .title {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 6px solid var(--primaryColor);
      margin: 0 auto;
      max-width: 850px;
    }

    & > .content {
      margin: 0 auto;
      max-width: 850px;
      padding-top: 15px;
      padding-bottom: 15px;
      white-space: pre-wrap;

      & > .expand-container {
        border-bottom: 3px solid #c8c8c8;
        padding-top: 10px;
        padding-bottom: 10px;

        & > .expand-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--primaryColor);
          font-size: 0.95rem;
          cursor: pointer;
        }

        & > .expand-button {
        }

        & > .expand-content {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }

  & > .rwd-fixed-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--primaryColor);

    ${(props) => (props.mobile ? `display: block;` : `display: none;`)}
  }
`;

function MobileNavDrawer(props) {
  const {visible, setVisible} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <>
      <MobileNavDrawerBackdrop
        mobile={mobile}
        visible={visible}
        onClick={() => setVisible(false)}
      />
      <MobileNavDrawerWrapper mobile={mobile} visible={visible}>
        <div className="container">
          <Menu mode="inline">
            <Menu.Item className="header">{TITLE}</Menu.Item>
            {ROUTES.map((item, index) => {
              if (item.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={`root-${index}`}
                    className="route"
                    title={item.label}>
                    {item.children.map((subroute, index) => (
                      <Menu.Item
                        key={`subroute-${index}`}
                        onClick={() => {
                          AppActions.navigate(subroute.key);
                          setVisible(false);
                        }}>
                        {subroute.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }
              return (
                <Menu.Item
                  key={`subroute-${index}`}
                  className="route"
                  onClick={() => {
                    AppActions.navigate(item.key);
                    setVisible(false);
                  }}>
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </MobileNavDrawerWrapper>
    </>
  );
}

const MobileNavDrawerBackdrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.5);

  ${(props) => (props.visible ? `display: block;` : `display: none;`)};
`;

const MobileNavDrawerWrapper = styled.div`
  width: 256px;
  height: 100vh;

  position: absolute;
  top: 83px;
  left: -256px;
  transition: 300ms;

  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 30px 10px;

  ${(props) => (props.visible ? `left: 0px;` : ``)}

  & > .container {
    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  max-width: 210px;
  width: 100%;
`;

export default withPageEntry(LawService);
